<template>
    <div>
        <b-form-group>
            <b-form-checkbox v-model="localConfig.quantity_selection"> Allow quantity selection</b-form-checkbox>
        </b-form-group>
        <b-form-group>
            <label>ERP Id:</label>
            <b-form-input
                type="text"
                placeholder="ERP Id"
                v-model="localConfig.erp_id"
            />
        </b-form-group>
        <b-form-group>
            <label>Max quantity:</label>
            <b-form-input
                type="number"
                placeholder="Max quantity"
                v-model="localConfig.max_quantity"
            />
        </b-form-group>
    </div>
</template>
<script>
    import {BFormGroup, BFormInput, BFormCheckbox} from 'bootstrap-vue'

    export default {
        components: {
            BFormInput,
            BFormCheckbox,
            BFormGroup
        },
        props: {
            value: {
                type: Object,
                request: true
            }
        },
        data() {
            return {
                localConfig: {
                    quantity_selection: true,
                    erp_id: '',
                    max_quantity: 0
                }
            }
        },
        watch: {
            value: {
                handler() {
                    if (JSON.stringify(this.localConfig) !== JSON.stringify(this.value)) {
                        this.localConfig = {...this.localConfig, ...this.value}
                    }
                },
                deep: true
            },
            localConfig: {
                handler() {
                    const localConfigWithNum = {
                        quantity_selection: this.localConfig.quantity_selection,
                        erp_id: this.localConfig.erp_id,
                        max_quantity: Number(this.localConfig.max_quantity)
                    }
                    if (JSON.stringify(localConfigWithNum) !== JSON.stringify(this.value)) {
                        this.$emit('input', localConfigWithNum)
                    }
                },
                deep: true
            }
        },
        created() {
            this.localConfig = {...this.localConfig, ...this.value}
        }
    }
</script>