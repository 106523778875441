<template>
    <div>
        <b-form-group>
            <label>Package:</label>
            <b-form-input
                type="text"
                placeholder="Package"
                v-model="localConfig.package"
            />
        </b-form-group>
    </div>
</template>
<script>
    import {BFormGroup, BFormInput} from 'bootstrap-vue'

    export default {
        components: {
            BFormInput,
            BFormGroup
        },
        props: {
            value: {
                type: Object,
                request: true
            }
        },
        data() {
            return {
                localConfig: {
                    package: ''
                }
            }
        },
        watch: {
            value: {
                handler() {
                    if (JSON.stringify(this.localConfig) !== JSON.stringify(this.value)) {
                        this.localConfig = {...this.localConfig, ...this.value}
                    }
                },
                deep: true
            },
            localConfig: {
                handler() {
                    if (JSON.stringify(this.localConfig) !== JSON.stringify(this.value)) {
                        this.$emit('input', this.localConfig)
                    }
                },
                deep: true
            }
        },
        created() {
            this.localConfig = {...this.localConfig, ...this.value}
        }
    }
</script>