<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card>
                <div class="d-flex justify-content-between">
                    <h2>Actions</h2>
                </div>

                <basic-table :columns="columns" :data="actions" v-slot="props">
                    <div v-if="props.column.displayType === 2" class="d-flex justify-content-center">

                        <b-button variant="warning" @click="$refs.editActionModal.open(props.row)" class="mr-1">
                            <feather-icon
                                icon="EditIcon"
                            />
                        </b-button>

                        <b-button variant="danger" @click="removeAction(props.row.id)">
                            <feather-icon
                                icon="Trash2Icon"
                            />
                        </b-button>
                    </div>
                    <span v-else-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-badge v-if="props.formattedRow[props.column.field] === 'Yes'" variant="light-success">
                            Yes
                        </b-badge>
                        <b-badge v-else variant="light-danger">
                            No
                        </b-badge>
                    </span>
                    <span v-else-if="props.column.displayType === 3" class="d-flex justify-content-center">
                        <b-table :items="getConfigData(props.row.type, props.row.config)" :fields="[{label: 'Property', key: 'name'},{label: 'Value', key: 'value'}]">
                            <template #cell()="data">
                                {{ data.value }}
                            </template>
                            <template #cell(value)="data">
                                <div v-if="String(data.value).startsWith('#')" >
                                    <div :style="{backgroundColor: data.value, color: data.value}" class="rounded-sm">Color</div>
                                </div>
                                <div v-else>
                                    {{data.value}}
                                </div>
                            </template>

                        </b-table>
                    </span>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </basic-table>
            </b-card>
        </b-overlay>

        <ActionEditModal ref="editActionModal"  v-on:actionSaved="loadData"/>
    </div>
</template>
<script>

    import BasicTable from '@/views/components/BasicTable'
    import {BBadge, BButton, BCard, BOverlay, BTable} from 'bootstrap-vue'
    import ActionEditModal from '@/views/GenericItem/Item/Actions/ActionEditModal'

    export default {
        components: {
            ActionEditModal,
            BasicTable,
            BOverlay,
            BCard,
            BButton,
            BBadge,
            BTable
        },
        data() {
            return {

                dataLoaded: [],
                actions: [],

                columns: [
                    {
                        label: 'Item name',
                        displayType: 0,
                        field: 'item_name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Item name'
                        }
                    },
                    {
                        label: 'Base item',
                        displayType: 0,
                        field: 'item_base_name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Base item'
                        }
                    },
                    {
                        label: 'Type',
                        displayType: 0,
                        field: this.typeRenderer,
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Type'
                        }
                    },
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Name'
                        }
                    },
                    {
                        label: 'Fully translated',
                        displayType: 1,
                        field(rowObj) {
                            return (rowObj.fully_translated) ? 'Yes' : 'No'
                        },
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Yes / No'
                        }
                    },
                    {
                        label: 'Config',
                        displayType: 3,
                        field: 'config',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search config'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 2,
                        field: 'actions',
                        filterOptions: {
                            enabled: false
                        }
                    }
                ]
            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false

                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/actions')
                loadPromise.then(function(response) {
                    thisIns.actions = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            removeAction(id) {
                const thisIns = this
                const loadPromise = this.$http.delete(`/api/management/v1/actions/${  id}`)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Action removed')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            filterConfigData(configData) {

                const quantitySelection =  (configData.quantity_selection)
                const customName = (configData.custom_name_override)
                return Object.entries(configData)
                    .filter(configRow => this.nameMap.has(configRow['0']))
                    .filter(configRow => configRow['0'] !== 'max_quantity' || quantitySelection)
                    .filter(configRow => configRow['0'] !== 'custom_name' || customName)

            },
            getConfigData(type, data) {

                const finalData = []
                switch (type) {
                case 0:
                    finalData.push(
                        {name: 'Color', value: data.color},
                        {name: 'Background color', value: data.background_color},
                        {name: 'Data', value: data.data}
                    )
                    break
                case 1:
                    finalData.push(
                        {name: 'ERP ID', value: data.erp_id},
                        {name: 'Quantity Selection', value: (data.quantity_selection) ? 'Yes' : 'No'}
                    )

                    if (data.quantity_selection) finalData.push({name: 'Maximum quantity', value: data.max_quantity})
                    break
                case 2:
                    finalData.push(
                        {name: 'Reservation system ID', value: data.reservation_system_id},
                        {name: 'Quantity Selection', value: (data.quantity_selection) ? 'Yes' : 'No'}
                    )

                    if (data.quantity_selection) finalData.push({name: 'Maximum quantity', value: data.max_quantity})
                    break
                case 3:
                    finalData.push({name: 'Package', value: data.package})
                    break
                case 4:
                    finalData.push(
                        {name: 'Price', value: (Number(data.price) / 100).toFixed(2)},
                        {name: 'VAT', value: data.vat},
                        {name: 'Quantity Selection', value: (data.quantity_selection) ? 'Yes' : 'No'}
                    )

                    if (data.quantity_selection) finalData.push({name: 'Maximum quantity', value: data.max_quantity})
                    if (data.custom_name_override) finalData.push({name: 'Custom Name', value: data.custom_name})
                    break

                }
                return finalData
            },
            typeRenderer(rowObj) {
                switch (rowObj.type) {
                case 0:
                    return 'QR Code'
                case 1:
                    return 'ERP Request'
                case 2:
                    return 'Reservation'
                case 3:
                    return 'Android package'
                case 4:
                    return 'Clocks PMS+ Entry'
                default:
                    return 'Unknown'
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }

</script>
