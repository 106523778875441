<template>
    <div>
        <b-form-group>
            <label>Price:</label>
            <b-form-input
                type="text"
                placeholder="Price"
                v-model="localConfig.price"
            />
            <label>VAT Rate:</label>
            <b-form-input
                type="number"
                placeholder="22.0"
                v-model.number="localConfig.vat"
            />
            <b-form-group class="mt-1">
                <b-form-checkbox v-model="localConfig.quantity_selection"> Allow quantity selection</b-form-checkbox>
            </b-form-group>
            <b-form-group v-if="localConfig.quantity_selection">
                <label>Max quantity:</label>
                <b-form-input
                    type="number"
                    placeholder="Max quantity"
                    v-model="localConfig.max_quantity"
                />
            </b-form-group>
            <b-form-checkbox
                class="mt-1"
                v-model="localConfig.custom_name_override"
            >Override invoice name:</b-form-checkbox>
            <div v-if="localConfig.custom_name_override">
                <label>Custom invoice name:</label>
                <b-form-input
                    type="text"
                    placeholder="Custom name"
                    v-model="localConfig.custom_name"
                />
            </div>
        </b-form-group>
    </div>
</template>
<script>
    import {BFormGroup, BFormInput, BFormCheckbox} from 'bootstrap-vue'

    export default {
        components: {
            BFormInput,
            BFormGroup,
            BFormCheckbox
        },
        props: {
            value: {
                type: Object,
                request: true
            }
        },
        data() {
            return {
                localConfig: {
                    price: '',
                    vat: 0.0,
                    custom_name: '',
                    custom_name_override: false,
                    quantity_selection: true,
                    max_quantity: 0
                }
            }
        },
        watch: {
            value: {
                handler() {
                    if (JSON.stringify(this.localConfig) !== JSON.stringify(this.value)) {
                        this.localConfig = {...this.localConfig, ...this.value}
                    }
                },
                deep: true
            },
            localConfig: {
                handler() {
                    this.localConfig.max_quantity = Number(this.localConfig.max_quantity)
                    if (JSON.stringify(this.localConfig) !== JSON.stringify(this.value)) {
                        this.$emit('input', this.localConfig)
                    }
                },
                deep: true
            }
        },
        created() {
            this.localConfig = {...this.localConfig, ...this.value}
        }
    }
</script>
