<template>
    <div>
        <b-form-group>
            <label>Data:</label>
            <b-form-input
                type="text"
                placeholder="Data"
                v-model="localConfig.data"
            />
        </b-form-group>
        <b-row>
            <b-col>
                <b-form-group>
                    <label>Code color:</label>
                    <b-form-input
                        type="color"
                        placeholder="Code"
                        v-model="localConfig.color"
                    />
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group>
                    <label>Background color:</label>
                    <b-form-input
                        type="color"
                        placeholder="Background"
                        v-model="localConfig.background_color"
                    />
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    import {BCol, BFormGroup, BFormInput, BRow} from 'bootstrap-vue'

    export default {
        components: {
            BRow,
            BCol,
            BFormInput,
            BFormGroup
        },
        props: {
            value: {
                type: Object,
                request: true
            }
        },
        data() {
            return {
                localConfig: {
                    color: '#000000',
                    background_color: '#ffffff',
                    data: ''
                }
            }
        },
        watch: {
            value: {
                handler() {
                    if (JSON.stringify(this.localConfig) !== JSON.stringify(this.value)) {
                        this.localConfig = {...this.localConfig, ...this.value}
                    }
                },
                deep: true
            },
            localConfig: {
                handler() {
                    if (JSON.stringify(this.localConfig) !== JSON.stringify(this.value)) {
                        this.$emit('input', this.localConfig)
                    }
                },
                deep: true
            }
        },
        created() {
            this.localConfig = {...this.localConfig, ...this.value}
        }
    }
</script>