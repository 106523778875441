<template>
    <b-modal title="Add action" v-model="editModalActive" size="xl" no-close-on-backdrop>
        <template #default>

            <b-form-group>
                <label for="name">Type:</label>
                <b-form-input
                    type="text"
                    placeholder="Type"
                    disabled
                    :value="actionType"
                />
            </b-form-group>

            <b-form-group>
                <label for="name">Name:</label>
                <b-form-input
                    id="name"
                    type="text"
                    placeholder="Name"
                    v-model="editObject.name"
                />
            </b-form-group>

            <hr/>
            <h4>Action config</h4>
            <QRCode v-if="editObject.type === 0" v-model="editObject.config"/>
            <ERPRequest v-else-if="editObject.type === 1" v-model="editObject.config"/>
            <Reservation v-else-if="editObject.type === 2" v-model="editObject.config"/>
            <AndroidPackage v-else-if="editObject.type === 3" v-model="editObject.config"/>
            <ClocksEntry v-else-if="editObject.type === 4" v-model="editObject.config"/>

            <hr>
            <Translations class="mt-1" :base-url="'/api/management/v1/base_item/' + baseItemId + '/items/' + itemId + '/actions/' + editObject.id" :fields="[{name: 'Name', field: 'name', type: 0}]" v-on:translationsChanged="$emit('actionSaved')"/>
        </template>
        <template #modal-footer>
            <b-button variant="danger" @click="editModalActive = false">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Close</span>
            </b-button>

            <b-button variant="warning" @click="saveAction">
                <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                />
                <span class="align-middle">Save</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>

    import {BButton, BFormGroup, BFormInput, BModal} from 'bootstrap-vue'
    import QRCode from '@/views/GenericItem/Item/Actions/Config/QRCode'
    import Translations from '@/views/Translation/Translations'
    import ERPRequest from '@/views/GenericItem/Item/Actions/Config/ERPRequest'
    import Reservation from '@/views/GenericItem/Item/Actions/Config/Reservation'
    import AndroidPackage from '@/views/GenericItem/Item/Actions/Config/AndroidPackage'
    import ClocksEntry from '@/views/GenericItem/Item/Actions/Config/ClocksEntry'

    export default {
        components: {
            AndroidPackage,
            Reservation,
            ERPRequest,
            Translations,
            QRCode,
            BButton,
            BModal,
            BFormInput,
            BFormGroup,
            ClocksEntry
        },
        data() {
            return {
                editObject: {
                    type: 0,
                    name: '',
                    config: {}
                },
                editModalActive: false
            }
        },
        methods: {
            open(action) {
                this.editModalActive = true
                this.editObject = JSON.parse(JSON.stringify(action))
                if (this.editObject.config.price !== undefined) {
                    this.editObject.config.price = (this.editObject.config.price / 100).toFixed(2)
                }
            },
            saveAction() {
                const thisIns = this
                if (this.editObject.config.price !== undefined) {
                    this.editObject.config.price = Number((Number(this.editObject.config.price) * 100).toFixed(0))
                }
                const loadPromise = this.$http.put(`/api/management/v1/actions/${  this.editObject.id}`, this.editObject)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Action saved')
                    thisIns.editModalActive = false
                    thisIns.editObject = {}
                    thisIns.$emit('actionSaved')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            }
        },
        computed: {
            actionType() {
                switch (this.editObject.type) {
                case 0:
                    return 'QR Code'
                case 1:
                    return 'ERP Request'
                case 2:
                    return 'Reservation'
                case 3:
                    return 'Android package'
                case 4:
                    return 'Clocks Entry'
                default:
                    return 'Unknown'
                }
            }
        }
    }
</script>
